.guessField {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-size: contain;
    color: white;
    text-shadow: 0 0 3px #000;

}
