body {
    height: 100vh;
}

#root {
    height: 100%;
}

.background {
    position: fixed;
    z-index: -1;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
}
