.verticalCentered {
    display: flex;
    flex-direction: column;
    width: 70vw;
    max-width: 1000px;
    height: 100%;
    margin-bottom: 30px;
}

.horizontalCentered {
    display: flex;
    justify-content: center;
    height: 100%;
}

.title {
    display: flex;
    justify-content: center;
    padding: 30px;
    font-size: 200px;
    font-weight: bold;
    color: brown;
    font-family: Calibri, serif;
}

.title2 {
    display: flex;
    justify-content: center;
    padding: 50px;
    font-size: 50px;
    color: white;
    font-family: Calibri, serif;
    margin: -100px 0 0;
}

.success {
    text-align: center;
    font-size: 40px;
    color: white;
    font-family: Calibri, serif;
    margin: 0;
}

.successHighlighting {
    text-align: center;
    color: brown;
    font-size: 50px;
    font-weight: bold;
    font-family: Calibri, serif;
    margin: 0;
}