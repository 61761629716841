.guessProps {
    display: grid;
    column-gap: 2px;
    row-gap: 6px;
}
.guessProp {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    font-size: 16px;
    font-weight: bold;
    color: white;
    width: 90px;
    height: 90px;
}
.guessValue {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    border: 1px solid black;
    font-size: 16px;
    font-weight: bold;
    width: 80px;
    height: 80px;
}